<template>
  <section class="content">
    <div class="card card-primary">
      <div class="card-body">
        <div class="row">
          <div class="col-9">
            <div class="row form-horizontal">
              <div class="col-auto">
                <label class="control-label my-2 ml-2">
                  <h6>Filter Pencarian</h6>
                </label>
              </div>
              <div class="col-auto">
                <select class="form-control" @change="dataType($event)">
                  <option value="pickupTime">Dibuat Antara</option>
                  <option value="updatedOn">Update Antara</option>
                </select>
              </div>
              <div class="col-auto">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    ref="daterange"
                    class="form-control"
                    style="border-right: 0"
                  />
                  <div class="input-group-append">
                    <div
                      class="input-group-text"
                      style="background-color: #fff"
                    >
                      <span><span class="fa fa-calendar"></span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto" v-if="false">
                <button
                  class="btn btn-info dropdown-icon dropdown-toggle"
                  @click="selectStatus"
                >
                  Status
                </button>
              </div>
            </div>
          </div>
          <div class="col-3 text-right" v-if="false">
            <div class="btn-group" ref="toolbar">
              <button type="button" data-action="read" class="btn btn-default">
                <i class="fa fa-sync-alt"></i>
              </button>
              <button
                type="button"
                :disabled="downloading"
                v-on:click="downloadXlsx"
                data-action="export-xlsx"
                class="btn btn-default"
              >
                <i class="fa fa-download"></i> Download
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsivex">
          <table class="table table-hover" ref="tableuser" id="tblretur">
            <thead>
              <tr>
                <th>NO. ORDER</th>
                <th>NO. RESI</th>
                <th>PENGIRIM</th>
                <th>KURIR</th>
                <th>PENERIMA</th>
                <th>TELEPON</th>
                <th>TUJUAN</th>
                <th>TANGGAL</th>
                <th>BERAT(KG)</th>
                <th>COD</th>
                <th>NILAI BARANG</th>
                <th>NILAI COD</th>
                <th>ONGKIR</th>
                <th>CASHBACK</th>
                <th>FEE COD</th>
                <th>PPN COD</th>
                <th>ASURANSI</th>
                <th>GRAND TOTAL</th>
                <th>STATUS</th>
                <th>BARANG DIBAYAR</th>
                <th>CASHBACK DIBAYAR</th>
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody @click="handleClick"></tbody>
          </table>
        </div>
      </div>
    </div>
    <Historyfilter :show="show" :sTicket="false" :sOther="false" v-model="filter" v-on:change="updateFilter" />
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Historyfilter from "@/dialog/Historyfilter";
import moment from "moment";

export default {
  name: "OngkirRetur",
  data() {
    return {
      dateStart: moment().startOf("month").format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      errors: [],
      method: "",
      roles: "",
      groups: [],
      formTitle: "Tambah User",
      form: {
        name: "",
        username: "",
        email: "",
      },
      downloading: false,
      show: false,
      filter: {},
      data_type: "pickupTime",
    };
  },
  computed: {
    kurirLogo() {
      const fileName = "jne"; //logo.toLowerCase()

      return require(`../assets/img/${fileName}.png`); // the module request
    },
  },
  components: {
    Historyfilter,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();

    authFetch("/users/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    downloadXlsx: function (e) {
      this.downloading = true;
      var filter = this.filter;
      filter.d1 = this.dateStart;
      filter.d2 = this.dateEnd;
      filter.datatype = this.data_type;

      var data = Object.keys(filter)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
        )
        .join("&");
      authFetch("/report/payment/excell", {
        method: "POST",
        body: data,
      })
        .then((response) => response.blob())
        .then((blob) => {
          setTimeout(() => {
            this.downloading = false;
          }, 1000);
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "data.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    updateFilter: function (e) {
      this.filter = e;
      console.log(this.filter);
      this.table.api().ajax.reload();
    },
    dataType(event) {
      this.data_type = event.target.value;
      this.table.api().ajax.reload();
    },
    onDate() {
      // var params = { dt1: this.dateStart, dt2: this.dateEnd };
      // var search = new URLSearchParams(params);
      this.table.api().ajax.reload();
    },
    selectStatus: function () {
      this.show = !this.show;
      console.log("click", this.show);
    },
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();

      return require(`../assets/img/${fileName}.png`); // the module request
    },
    handleClick(e) {
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
          this.$router.push({
            path: "/payment-shipper/retur/detail/" + e.target.dataset.id,
          });
        }
        return false;
      }
    },
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(
      this.$refs.daterange,
      {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
      function (d1, d2) {
        self.dateStart = d1.format("YYYY-MM-DD");
        self.dateEnd = d2.format("YYYY-MM-DD");
        self.data_type = self.data_type;
        self.onDate();
      }
    );
    console.log("logo", self.getKurirLogo("jne"));
    var isShipper = this.userInfo.role_id > 10;
    var isKurir = this.userInfo.role_id == 9;
    this.table = createTable(e.tableuser, {
      title: "Data Ongkir Retur",
      roles: this.$route.params.roles,
      ajax: "/payment/shipper/retur",
      frame: false,
      toolbarEl: e.toolbar,
      lengthChange: true,
      filter: true,
      dom: "<'row'<'col-sm-12 col-md-6 btn-area-selected'><'col-sm-12 col-md-6'f>>" +
				"<'row'<'col-sm-12'tr>>" +
				"<'row'<'col-sm-12 col-md-2'l><'col-sm-12 col-md-3'i><'col-sm-12 col-md-7'p>>",
      "scrollX": true,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      paramData: function (d) {
        d.cod = self.filter.cod ? 1 : 0;
        d.noncod = self.filter.noncod ? 1 : 0;
        d.ts0 = self.filter.ts0 ? 1 : 0;
        d.ts1 = self.filter.ts1 ? 1 : 0;
        d.ts2 = self.filter.ts2 ? 1 : 0;
        d.ts3 = self.filter.ts3 ? 1 : 0;
        d.ts4 = self.filter.ts4 ? 1 : 0;
        d.d1 = self.dateStart;
        d.d2 = self.dateEnd;
        d.datatype = self.data_type;
      },
      columns: [
        { data: "order_code" },
        { data: "billNo" },
        {
          data: "gudang",
          title: isShipper ? "ASAL GUDANG" : "Shipper",
          render: function (data, type, row, meta) {
            return data;
          },
        },
        {
          data: "courierCode",
          visible: isKurir ? false : true,
          render: function (data, type, row, meta) {
            var logo = data.toLowerCase();
            return '<img height="16" src="' + self.getKurirLogo(logo) + '" />';
          },
        },
        {
          data: "destName",
          title: "PENERIMA",
          render: function (data, type, row, meta) {
            return row.destName;
          },
        },
        { data: "destTel" },
        {
          data: "destCity",
          width: "270px",
          render: function (data, type, row, meta) {
            return row.kecamatan + ", " + row.destCity;
          },
        },
        {
          data: "pickupTime",
          render: function (data, type, row, meta) {
            return data;
          },
        },
        { data: "weight" },
        { data: "codStatus",
          render: function (data, type, row, meta) {
            if(data == 1)
            return '<span class="badge badge-success">YA</span>';
            else if (data == 0)
            return '<span class="badge badge-warning">TIDAK</span>';
          }, 
        },
        {
          data: "item_value",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.item_value);
          },
        },
        {
          data: "codValue",
          render: function (data, type, row, meta) {
            let nilaicod = row.codValue;
            if (nilaicod == "0.00") {
              return "Rp 0";
            } else {
              return "Rp " + formatCurrency(row.codValue);
            }
          },
        },
        {
          data: "nilai_ongkir",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.nilai_ongkir);
          },
        },
        {
          data: "disc",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.disc);
          },
        },
        {
          data: "feeCod",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.feeCod);
          },
        },
        {
          data: "ppnCod",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.ppnCod);
          },
        },
        {
          data: "insurance_fee",
          render: function (data, type, row, meta) {
            let insurance = '';
            if(row.insurance_type == 0){
              insurance = '-';
            } else if(row.insurance_type == 1 && row.insurance_fee < 1){
              insurance = 'FREE';
            } else if(row.insurance_type == 1 && row.insurance_fee > 0) {
              insurance = "Rp " + formatCurrency(row.insurance_fee);
            }
            
            return insurance;
          },
        },
        {
          data: "grand_total",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(row.grand_total);
          },
        },
        {
          data: "msgStatus",
          render: function (data, type, row, meta) {
            if (row.pickupStatus == 0)
              return '<span class="badge badge-inverse">DRAFT</span>';
            else {
              if (row.trackingStatus == 0)
                if(row.order_type == 1){
                  return '<span class="badge badge-secondary">Menunggu Drop</span>';
                } else {
                  return '<span class="badge badge-secondary">Menunggu Pickup</span>';
                }
              else if (row.trackingStatus == 1)
                return '<span class="badge badge-warning">Proses</span>';
              else if (row.trackingStatus == 2)
                return '<span class="badge badge-success">Terkirim</span>';
              else if (row.trackingStatus == 3)
                return '<span class="badge badge-danger">Proses Retur</span>';
              else return '<span class="badge badge-info">Retur Selesai</span>';
            }
          },
        },
        {
          data: "dibayar",
          sortable: false,
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        {
          data: "belumdibayar",
          sortable: false,
          render: function (data, type, row, meta) {
            return "-";
          },
        },
        { data: "order_no",
        render: function (data, type, row, meta) {
          return `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
            row.order_no +
            `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`;
        } },
      ],
      filterBy: [0, 1, 2, 4, 5, 6],
      rowCallback: function (row, data) {
        // $("td:eq(0)", row).html(
        //   '<a class="link link-order text-info" style="cursor:pointer;" data-id="' +
        //     data.order_code +
        //     '">' +
        //     data.order_code +
        //     "</a>"
        // );

        // $("td:eq(15)", row).html(
          
        // );
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>
<style scoped>
th {
  text-align: center;
  vertical-align: middle;
}
.dataTables_length{padding-top: 0.5em;}
</style>